.home-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 98;
  h1 {
    text-align: center;
    font-size: 4em;
    font-weight: 700;
    color: white;
    cursor: default;
    user-select: none;
  }

  h3 {
    text-align: center;
    color: white;
    font-size: 2em;
    cursor: default;
    user-select: none;
    font-weight: 300;

    #dev {
      color: #00ff40;
    }
  }
}
