.contact-container {
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 98;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;

    h1 {
      font-size: 2.75em;
      color: white;
      font-weight: 700;
      text-align: center;
    }

    span {
      color: #25ca4e !important;
      font-size: 2em;
    }
  }

  .contact__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .contact-card{
        margin: 10px;
        background-color: rgba(0, 0, 0, 0);
        width: 350px;
        height: 250px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

          img {
            height: 60px;
          }
    
          &:hover {
            transform: scale(1.05);
            transition: 0.3s ease;
          }
    
          a {
              margin-top: 10px;
            text-decoration: none;
    
            h3 {
              color: white;
              font-size: 1.5em;
            }
          }
    }
  }
}
