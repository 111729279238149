.mobile{
  width: 100vw;
  height: 100vh;
  background-color: #101010;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;


  span{
      position: relative;
      

     #close{
         font-size: 45px;
         color: white;
         margin-top: 10px;
     }
  }
  .links{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 150px;
      



      a {
          text-decoration: none;
          color: white;
          font-size: 25px;
          margin: 15px;
      }
  }

  .icon{
      display: flex;
      justify-content: center;
      position: absolute;
      height: 10%;
      width: 100vw;


      svg{
          color: #25ca4e;
      }
  }
  
}

.ghost-wrapper {
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 98;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.322);
}