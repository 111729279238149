@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;700&display=swap');
* {
  box-sizing: border-box;
  

  #favicon{
    color: white;
  }
}
.main-container {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.123), rgba(0, 0, 0, 0.548)),
  url("../../assets/img/bckg4.png") no-repeat;
  background-position: center;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  font-family: 'Rubik', sans-serif;


  .topbar {
    width: 100%;
    height: 56px;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;


    svg{
      font-size: 50px;
      cursor: pointer;
      color: rgb(255, 255, 255);
      margin: 5px;
      border-radius: 20px;
     
    }
    @media (max-width: 1100px) {
      display: block;
      background-color: rgba(255, 255, 255, 0);
    }
  }
  .sidebar {
    width: 150px;
    height: 100%;
    max-height: 100%;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    @media (max-width: 1100px) {
      display: none;
    }
    #logo {
      position: absolute;
      height: 100px;
      top: 0;
      color: white;
      margin-top: 20px;
      
    }
    nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      font-size: 26px;

      a {
        text-decoration: none;
        color: white;
      }

      svg {
        

        &:hover {
          transform: scale(1.1);
          transition: all 0.3s ease;
          color: #25ca4e !important;
        }
      }
    }
  }


  .content {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 0, 0);
    padding: 0px 0 0 150px;
    overflow-x: hidden;

    @media (max-width: 1100px) {
      padding: 56px 0 0 0;
    }
  }


}