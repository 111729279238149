.projects-container {
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 98;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;

    h1 {
      font-size: 2.75em;
      color: white;
      font-weight: 700;
    }

    span {
      color: #25ca4e !important;
      font-size: 2em;
    }
  }

  #projects-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .projects__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .project {
      margin: 40px;
      background-color: rgba(0, 0, 0, 0);
      height: 320px;
      width: 600px;
      position: relative;
      @media (max-width: 700px) {
        width: 500px;
        height: 280px;
        margin: 20px;
      }

      @media (max-width: 560px) {
        width: 400px;
        height: 230px;
        margin: 10px;
      }

      @media (max-width: 450px) {
        width: 350px;
        height: 200px;
      }
      @media (max-width: 400px) {
        width: 300px;
        height: 170px;
      }

      #img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 10px;
        opacity: 0;
        transition: 0.5s ease;
        background-color: #101010;
        display: flex;

        .left {
          width: 15%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .aim {
            display: flex;
            justify-content: center;
            svg {
              margin-top: 30px;
              color: #25ca4e !important;
              font-size: 2.5rem;
            }
          }
          .external-link-left {
            display: none;

            @media (max-width: 560px) {
              margin-bottom: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            a {
              color: white;
            }
            span {
              font-size: 35px;
              color: white;

              @media (max-width: 400px) {
                font-size: 25px;
              }
            }
          }
        }

        .right {
          width: 80%;
          .text {
            padding: 15px;
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-top: 20px;

            @media (max-width: 560px) {
              margin-top: 0px;
            }

            @media (max-width: 450px) {
            }

            @media (max-width: 350px) {
              margin-top: 5px;
            }

            h1 {
              color: white;
              font-size: 2.25rem;
              @media (max-width: 560px) {
                font-size: 1.4rem;
              }

              @media (max-width: 400px) {
                font-size: 16px;
              }
            }

            h3 {
              margin-top: 30px;
              color: #25ca4e;
              font-size: 1.5rem;
              @media (max-width: 560px) {
                margin-top: inherit;
                font-size: 1.15rem;
              }
              @media (max-width: 420px) {
                margin-top: -5px;
              }
            }
            p {
              color: white;
              font-size: 1.2rem;
              font-weight: 300;
              @media (max-width: 560px) {
                font-size: 1rem;
              }
              @media (max-width: 450px) {
                margin-top: -10px;
              }
              @media (max-width: 400px) {
                font-size: 14px;
              }
            }
            .external-link {
              display: flex;
              gap: 5px;
              position: absolute;
              right: 20px;
              bottom: 0;
              margin: 10px;

              @media (max-width: 560px) {
                display: none;
              }

              a {
                color: white;
              }
              span {
                font-size: 35px;
                color: white;
              }
            }
          }
        }
      }

      &:hover .overlay {
        opacity: 1;
      }
    }
  }
}
