.resume-container {
  min-height: 100%;
  width: 100%;
  z-index: 98;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;

    h1 {
      font-size: 2.75em;
      color: white;
      font-weight: 700;
    }

    span {
      color: #25ca4e !important;
      font-size: 2em;
    }
  }

  .caption {
    display: flex;
    flex-direction: column;
    align-items: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 2.175em;
      color: white;
      font-weight: 700;
    }

    span {
      color: #25ca4e !important;
      font-size: 2em;
    }
  }

  .resumee-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5em;

    .experience-card {
      background-color: #101010;
      min-height: 420px;
      width: 30vw;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px;
      .experience {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 15px;
        .left {
          span {
            color: #25ca4e;
            font-size: 2.5rem;
          }
        }

        .right {
          margin-left: 10px;

          h3 {
            color: white;
            font-size: 1.5em;
          }
          h4 {
            color: #25ca4e;
            font-size: 1.15rem;
            padding-top: 10px;
            font-weight: 300;
          }
          h5 {
            color: #25ca4e;
            font-size: 0.85rem;
            padding-top: 5px;
            font-weight: 300;
          }
          p {
            color: white;
            padding-top: 20px;
            font-weight: 300;
            font-size: 1.15rem;
          }
        }
      }
      @media (max-width: 1200px) {
        width: 380px;
      }
      @media (max-width: 400px) {
        width: 350px;
      }
    }

    .education-card {
      background-color: #101010;
      min-height: 420px;
      width: 30vw;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px;
      .education {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px;
        .left {
          span {
            color: #25ca4e;
            font-size: 2.5rem;
          }
        }

        .right {
          margin-left: 10px;

          h3 {
            color: white;
            font-size: 1.5em;
          }
          h4 {
            color: #25ca4e;
            font-size: 1.15rem;
            padding-top: 10px;
            font-weight: 300;
          }
          p {
            color: white;
            padding-top: 5px;
            font-weight: 300;
            font-size: 1.15rem;
          }
        }
      }
      @media (max-width: 1200px) {
        width: 380px;
      }
      @media (max-width: 400px) {
        width: 350px;
      }
    }
  }

  .skills-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: calc(60vw + 5em);
    margin: auto;

    .skills-card {
      background-color: #101010;
      height: auto;
      width: 100%;
      border-radius: 10px;
      margin: 10px;
      padding: 30px;
      min-width: 380px;
      @media (max-width: 1200px) {
        width: calc(760px + 5em);
      }
      @media (max-width: 857px) {
        width: 380px;
      }
      h3 {
        color: white;
        display: flex;
        justify-content: center;
        padding: 10px;
        font-size: 2.5em;
        font-weight: 300;
      }

      .grid {
        padding: 25px;
        gap: 25px;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        @media (max-width: 2328px) {
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 1497px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 1089px) {
          grid-template-columns: 1fr;
        }
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 20px;
          img {
            height: 110px;
            transition: all 0.3s ease-in-out;
            &:hover {
              transform: scale(1.1);
            }
          }
          p {
            padding-top: 20px;
            color: #fff;
            font-size: 1.3rem;
            font-weight: 300;
            text-align: center;
          }
        }
      }
    }
  }
}
