.about-container {
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 98;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    text-align: center;

    h1 {
      font-size: 2.75em;
      color: white;
      font-weight: 500;
    }

    span {
      color: #25ca4e !important;
      font-size: 2em;
    }
  }

  .about-card {
    background-color: #101010;
    max-width: 60vw;
    height: 300px;
    margin: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 5em;

    .left {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      gap: 1em;

      .my-img {
        display: inline-block;
        width: 180px;
        height: 180px;
        background: url("../../../assets/img/me.jpeg");
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 50%;
        border: 3px solid #25ca4e;
      }

      button,
      button::after {
        margin: auto;
        width: 180px;
        height: 36px;
        font-size: 20px;
        font-family: "Noto Sans", sans-serif;
        font-family: "Roboto", sans-serif;
        font-family: "Ubuntu", sans-serif;
        background: linear-gradient(45deg, transparent 5%, #151515 5%);
        border: 0;
        color: rgb(255, 255, 255);
        line-height: 6px;
        box-shadow: 6px 0px 0px #25ca4e !important;
        outline: transparent;
        position: relative;
        cursor: pointer;
      }

      button::after {
        --slice-0: inset(50% 50% 50% 50%);
        --slice-1: inset(80% -6px 0 0);
        --slice-2: inset(50% -6px 30% 0);
        --slice-3: inset(10% -6px 85% 0);
        --slice-4: inset(40% -6px 43% 0);
        --slice-5: inset(80% -6px 5% 0);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          45deg,
          transparent 3%,
          #25ca4e 3%,
          #25ca4e 5%,
          #25ca4e 5%
        );
        text-shadow: -3px -3px 0px #f8f005, 3px 3px 0px #25ca4e;
        clip-path: var(--slice-0);
      }
      button:hover::after {
        animation: 1s glitch;
        animation-timing-function: steps(2, end);
      }
    }

    .right {
      height: 100%;
      padding-right: 20px;
      text-align: center;
      text-justify: inter-word;
      display: flex;
      align-items: center;
      p {
        font-size: 1.25rem;
        color: white;
        font-weight: 300;
      }
    }

    @media (max-width: 1100px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      max-width: 350px;
      height: 600px;
      padding: 0 20px 0 20px;
      gap: 0;

      .left {
        display: flex;
        flex-direction: column;
        padding-left: 0px;
        gap: 0.5em;
        padding-top: 20px;
        img {
          height: 11em;
          border-radius: 50%;
        }
      }

      .right {
        padding: 15px;
        p {
          text-align: center;
          font-size: 18px;
          letter-spacing: 0.3px;
          color: white;
        }
      }
    }
  }

  .interests {
    margin: auto;
    display: flex;
    justify-content: center;
    background-color: #101010;
    border-radius: 10px;
    width: 1000px;
    @media (max-width: 1450px) {
      width: 600px;
    }
    @media (max-width: 1100px) {
      width: 350px;
    }
  }

  .inter-card {
    height: auto;
    margin: auto;
    color: #fff;
    padding: 0 20px 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: 1450px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 1100px) {
      grid-template-columns: 1fr;
    }

    .inter-icon {
      border-radius: 10px;
      width: 200px;
      min-height: 45px;
      position: relative;
      margin: 20px;
      transition: all 0.3s ease-in-out;
      background-color: #151515;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;
      user-select: none;
      @media (max-width: 1450px) {
        width: 250px;
      }

      &:hover {
        border: 1px solid #25ca4e;
        transition: all 0.5s ease-in-out;
      }
      .icons-int {
        position: relative;
        width: 30px;
        float: left;
        font-size: 20px;
        color: #25ca4e !important ;
      }
      .desc-inter {
        font-size: 1.3em;
        color: white;
        font-weight: 300;
      }
    }
  }

  .services-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: default;
    user-select: none;

    .service-card {
      background-color: #101010;
      height: 250px;
      width: 30vw;
      border-radius: 10px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon-service {
        margin-top: 10px;
        background-color: #151515;
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &:hover {
          border: 1px solid #25ca4e;
          transition: all 0.5s ease-in-out;
        }

        svg {
          font-size: 1.8em;
          color: white;
        }
      }
      h3 {
        margin-top: 10px;
        color: white;
        font-size: 1.4em;
        font-weight: 300;
      }

      @media (max-width: 1300px) {
        width: 400px;
      }
    }
  }
}
